<template>
  <section class="dtc-main-section input-container">
    <h4>
      已服務期滿仍繼續留任(意即留在原民族及離島地區繼續任職)的養成公費醫事人員請填寫該份表單(共
      19 題)
    </h4>
    <header
      class="dtc-2-column header-dark"
      style="text-align:center;height:30px;"
    >
      <div>問題</div>
      <div>回答</div>
    </header>
    <main class="dtc-2-column">
      <div class="number-div">
        <div>1</div>
        <div v-text="itemList[0].text" />
      </div>
      <div>
        <template>
          <b-form-checkbox-group
            stacked
            v-model="itemList[0].anwser"
            :options="itemList[0].options"
            @change="updateItems(itemList[0])"
            :disabled="isQ2Exist || isQ3Exist"
          ></b-form-checkbox-group>
        </template>
        <b-input
          v-model="itemList[0].other"
          :disabled="isQ2Exist || isQ3Exist"
        ></b-input>
      </div>
    </main>
    <main class="dtc-2-column">
      <div class="number-div">
        <div>2</div>
        <div v-text="itemList[1].text" />
      </div>
      <div>
        <template>
          <b-form-checkbox-group
            stacked
            v-model="itemList[1].anwser"
            :options="itemList[1].options"
            @change="updateItems(itemList[1])"
            :disabled="isQ1Exist || isQ3Exist"
          ></b-form-checkbox-group>
        </template>
        <b-input
          v-model="itemList[1].other"
          :disabled="isQ1Exist || isQ3Exist"
        ></b-input>
      </div>
    </main>
    <main class="dtc-2-column">
      <div class="number-div">
        <div>3</div>
        <div v-text="itemList[2].text" />
      </div>
      <div>
        <template>
          <b-form-checkbox-group
            stacked
            v-model="itemList[2].anwser"
            :options="itemList[2].options"
            :disabled="isQ1Exist || isQ2Exist"
            @change="updateItems(itemList[2])"
          ></b-form-checkbox-group>
        </template>
        <b-input
          v-model="itemList[2].other"
          :disabled="isQ1Exist || isQ2Exist"
        ></b-input>
      </div>
    </main>

    <main
      class="dtc-2-column"
      v-for="(item, idx) in itemList"
      :key="'item' + idx"
      :class="idx < 3 ? 'active' : ''"
    >
      <div class="number-div" v-if="idx > 2">
        <div v-text="idx + 1" />
        <div v-text="item.text" />
      </div>
      <div v-if="idx > 2">
        <template v-if="item.hasOwnProperty('options')">
          <b-form-radio-group
            v-if="
              typeof item.anwser === 'string' ||
                typeof item.answer == 'number' ||
                item.anwser == null
            "
            stacked
            v-model="item.anwser"
            :options="item.options"
          ></b-form-radio-group>
          <b-form-checkbox-group
            v-else
            stacked
            v-model="item.anwser"
            :options="item.options"
            @change="updateItems(item)"
          ></b-form-checkbox-group>
        </template>

        <template v-else>
          <label>簡答</label>
          <b-textarea v-model="item.anwser" row="5" />
        </template>

        <b-input
          v-if="item.hasOwnProperty('other')"
          v-model="item.other"
        ></b-input>
      </div>
    </main>
    <b-button
      size="sm"
      variant="success"
      class="mr-2"
      style="display:inline-block;width:100px;height:40px;margin-top:20px;margin-left:750px"
      @click="submitAnswer"
      v-show="!closeBtn"
    >
      <i class="fas fa-save mr-1"></i>儲存問卷
    </b-button>
  </section>
</template>

<script>
import queryString from "query-string";
import { store } from "@/store/global.js";
const options1 = [
  "1 自己的專科無法發揮及精進",
  "2 對公衛保健很陌生",
  "3 對公衛保健沒有興趣",
  "4 業務包山包海、很繁雜",
  "5 沒有其他專科同儕可以互動",
  "6 有人事/人際問題",
  "7 病人數偏少",
  "8 薪資福利等誘因較醫院來的低",
  "9 病人會對醫生或單位有不信任感",
  "10 其他:",
];
const options2 = [
  "1 需要輪班",
  "2 需要支援其他科別",
  "3 院內行政僵化，包括主管行政風格等",
  "4 有人事/人際問題",
  "5 病人數偏少",
  "6 沒有額外福利，例如服務年資加",
  "成計算、偏鄉加給等",
  "7 病人會對醫生或單位有不信任感",
  "8 其他:",
];
const options3 = [
  "1 自己的專科無法發揮及精進",
  "2 沒有繼續進修或訓練的機會",
  "3 業務包山包海、很繁雜",
  "4 沒有其他專科同儕可以互動",
  "5 有人事/人際問題",
  "6 病人數偏少",
  "7 薪資福利等誘因較醫院來的低",
  "8 病人會對醫生或單位有不信任感",
  "9 其他",
];
const options4 = [
  "1 只有這裡有缺",
  "2 當地的生活機能，包括交通、休閒等",
  "3 當地的親密伴侶職涯發展有關的資源",
  "4 當地的子女教養資源",
  "5 可否就近照顧父母、配偶子女等家人",
  "6 服務單位內的業務性質與工作量",
  "7 薪水、福利",
  "8 其他",
];
const options5 = ["1 很順利", "2 還好", "3 不算順利", "4 非常波折"];
const options6 = ["1 很有幫助", "2 有一點幫助", "3 完全沒幫助"];
const options7 = [
  "1 沒有想過",
  "2 影響親密關係的發展",
  "3 影響未來子女教養規劃",
  "4 未來生活環境不太理想",
  "5 未來職涯規劃受限",
  "6 與自己想學專業不符",
  "7 合約內容與其他類公費生相比太苛刻",
  "8 其他",
];
const options8 = ["1 每次都會", "2 大部分時會", "3 不一定", "4 幾乎不會"];
const options9 = ["1 每次都會", "2 大部分時會", "3 不一定", "4 幾乎不會"];
const options10 = [
  "1 當地的生活機能差",
  "2 與親密伴侶或家人分隔兩地",
  "3 找不到合適的住家，包括租屋不便等",
  "4 家人不適應當地生活",
  "5 很難結交朋友",
  "6 難以融入當地生活文化，包括溝通困難等",
  "7 其他",
];
const options11 = [
  "1 沒有考慮/轉換過",
  "2 影響親密關係的發展",
  "3 影響子女教養規劃",
  "4 生活環境不太理想",
  "5 與自己想學專業不符",
  "6 不適應服務單位的工作",
  "7 不適應服務單位的人事行政管理",
  "8 不適應服務單位當地的風俗民情",
  "9 薪水福利考量",
  "10 其他",
];
const options12 = [
  "1 在此服務的成就感",
  "2 和當地居民建立起的醫病關係",
  "3 對原住民健康的使命感",
  "4 可否就近照顧家人",
  "5 生活和環境機能",
  "6 和整個服務單位團隊的配合度",
  "7 服務單位的工作量",
  "8 薪資及福利的考量",
  "9 其他",
];
const options13 = [
  "1 沒有考慮要離任",
  "2 影響親密關係維持",
  "3 影響子女教養規劃",
  "4 影響家人的照護安排",
  "5 親密伴侶職涯發展受限",
  "6 生活環境和機能不太理想",
  "7 專業職涯規劃受限",
  "8 不適應服務單位的工作",
  "9 不適應服務單位的人事行政管理",
  "10 薪水福利考量",
  "11 其他",
];
const options14 = [
  "1 協助親密伴侶職涯發展",
  "2 提升當地子女的教養資源",
  "3 提升當地長者照護資源",
  "4 改善當地生活環境",
  "5 改善服務單位的執業環境(包括:人事、工作量等)",
  "6 提升薪資、福利",
  "7 其他",
];
const options15 = [
  "1 回應很快也很有幫助",
  "2 回應很慢但很有幫助",
  "3 回應很快但沒有幫助",
  "4 回應很慢又沒有幫助",
  "5 近乎不理不睬",
  "6 沒有反映過",
];
const options16 = [
  "1 公費生的資訊交流平台",
  "2 公費生的實體互動",
  "3 專科精進輔導",
  "4 公衛保健專業輔導",
  "5 行政管理輔導",
  "6 人事/人際溝通協調訓練",
  "7 其他",
];
const options18 = ["1 願意，請聯絡我", "2 不願意"];
const options19 = ["1 願意，請聯絡我", "2 不願意"];

export default {
  data() {
    return {
      itemList: [
        {
          anwser: [],
          other: "",
          options: options1,
          text:
            "第一個服務地點是衛生所者請填)請問您當時在衛生所任職時遇到的問題或挑戰有哪些呢? (請選擇最重要的三項，接續填 4)",
        },
        {
          anwser: [],
          other: "",
          options: options2,
          text:
            "第一個服務地點是部立醫院者請填)請問您當時在部立醫院任職時遇到的問題或挑戰有哪些呢?(請選擇最重要的三項，接續填 4)",
        },
        {
          anwser: [],
          other: "",
          options: options3,
          text:
            "第一個服務地點是其他醫事機構者請填)請問您當時在其他醫事機構(非衛生所及醫院)服務時遇到的問題或挑戰有哪些呢?(請選擇最重要的三項，接續填4)",
        },
        {
          anwser: [],
          other: "",
          options: options4,
          text:
            "當初在決定第一個服務地點時，您的決定是受些因素影響呢?(請選擇最重要的三項)",
        },
        {
          anwser: "",
          options: options5,
          text: "整體來看，您在第一個服務單位的適應過程是:",
        },
        {
          anwser: "",
          options: options6,
          text:
            "若於學生時期提早讓您下鄉見實習，您認為對下鄉後的適應會有幫助嗎?",
        },
        {
          anwser: [],
          other: "",
          options: options7,
          text:
            "服務時期，如果曾考慮過放棄公費身份，請問是因為哪些原因呢?(請選擇最重要的三項)",
        },
        {
          anwser: "",
          options: options8,
          text:
            "服務時期，您的親密關係或家庭生活是否曾經因您的服務義務而有所影響呢?",
        },
        {
          anwser: "",
          options: options9,
          text: "在服務時期，您是否曾因公費身分提供醫療照護而感到自卑呢?",
        },
        {
          anwser: [],
          other: "",
          options: options10,
          text: "服務時期，您遇到的生活適應困難有哪些呢?",
        },
        {
          anwser: [],
          other: "",
          options: options11,
          text:
            "服務時期若您考慮或轉換過服務單位，請問是因為哪些原因呢? (請選擇最重要的三項)",
        },
        {
          anwser: [],
          other: "",
          options: options12,
          text:
            "請問哪些原因讓您服務期滿後仍選擇繼續留任(意即繼續在原民族及離島地區任職)呢?(請選擇最重要的三項)",
        },
        {
          anwser: [],
          other: "",
          options: options13,
          text:
            "若您近期有考慮過離任(意即離開原民族及離島地區服務，包括退休等)，請問是什麼原因呢?(請選擇最重要的三項)",
        },
        {
          anwser: [],
          other: "",
          options: options14,
          text: "請問怎麼樣的改變會促使您留任意願提升呢?(請選擇最重要的三項)",
        },
        {
          anwser: "",
          options: options15,
          text:
            "在服務期間，您是否有過跟公部門反映公費相關問題(例如:人事管理不公、合約內容問題等)，結果是",
        },
        {
          anwser: [],
          other: "",
          options: options16,
          text:
            "需求評估:服務時期，您會希望有以下那些協助呢?(請選擇最重要的三項)",
        },
        {
          anwser: "",
          text: "請問您對現有的養成公費政策有沒有任何想法及意見想提出呢?",
        },
        {
          anwser: "",
          options: options18,
          text:
            "意願調查:請問您願意擔任小手們的輔導老師，當學弟妹們有任何課業或是公費問題時想尋求建議時可以詢問您嗎?",
        },
        {
          anwser: "",
          options: options19,
          text:
            "意願調查:請問您願意接受我們的訪談，讓您的公費經歷可以讓後續的學弟妹的做參考嗎?",
        },
      ],
      isQ1Exist: false,
      isQ2Exist: false,
      isQ3Exist: false,
      closeBtn: false,
    };
  },
  props: ["allData"],
  methods: {
    updateItems(item) {
      if (item.text.includes("您遇到的生活適應困難有哪些")) {
        return;
      }
      requestAnimationFrame(() => {
        if (item.anwser.length < 4) return;
        item.anwser.shift();
      });
    },
    getFinalAnswer(answer, options) {
      let final = "";
      if (
        typeof answer == "string" ||
        typeof answer == "number" ||
        answer == null
      ) {
        final = options.map((s, i) => (answer == s ? "Y" : "N")).join(",");
      } else {
        final = options
          .map((s, i) => (answer.find((s2) => s2 == s) ? "Y" : "N"))
          .join(",");
      }
      return final;
    },
    async submitAnswer() {
      let RecordQ4 = {};
      RecordQ4.Question_01 = this.getFinalAnswer(
        this.itemList[0].anwser,
        this.itemList[0].options
      );
      RecordQ4.Question_01_Other = this.itemList[0].other;
      RecordQ4.Question_02 = this.getFinalAnswer(
        this.itemList[1].anwser,
        this.itemList[1].options
      );
      RecordQ4.Question_02_Other = this.itemList[1].other;
      RecordQ4.Question_03 = this.getFinalAnswer(
        this.itemList[2].anwser,
        this.itemList[2].options
      );
      RecordQ4.Question_03_Other = this.itemList[2].other;
      RecordQ4.Question_04 = this.getFinalAnswer(
        this.itemList[3].anwser,
        this.itemList[3].options
      );
      RecordQ4.Question_04_Other = this.itemList[3].other;
      RecordQ4.Question_05 = this.getFinalAnswer(
        this.itemList[4].anwser,
        this.itemList[4].options
      );
      RecordQ4.Question_06 = this.getFinalAnswer(
        this.itemList[5].anwser,
        this.itemList[5].options
      );
      RecordQ4.Question_07 = this.getFinalAnswer(
        this.itemList[6].anwser,
        this.itemList[6].options
      );
      RecordQ4.Question_07_Other = this.itemList[6].other;
      RecordQ4.Question_08 = this.getFinalAnswer(
        this.itemList[7].anwser,
        this.itemList[7].options
      );

      RecordQ4.Question_09 = this.getFinalAnswer(
        this.itemList[8].anwser,
        this.itemList[8].options
      );
      RecordQ4.Question_10 = this.getFinalAnswer(
        this.itemList[9].anwser,
        this.itemList[9].options
      );
      RecordQ4.Question_10_Other = this.itemList[9].other;
      RecordQ4.Question_11 = this.getFinalAnswer(
        this.itemList[10].anwser,
        this.itemList[10].options
      );
      RecordQ4.Question_11_Other = this.itemList[10].other;
      RecordQ4.Question_12 = this.getFinalAnswer(
        this.itemList[11].anwser,
        this.itemList[11].options
      );
      RecordQ4.Question_12_Other = this.itemList[11].other;
      RecordQ4.Question_13 = this.getFinalAnswer(
        this.itemList[12].anwser,
        this.itemList[12].options
      );
      RecordQ4.Question_13_Other = this.itemList[12].other;
      RecordQ4.Question_14 = this.getFinalAnswer(
        this.itemList[13].anwser,
        this.itemList[13].options
      );
      RecordQ4.Question_14_Other = this.itemList[13].other;
      RecordQ4.Question_15 = this.getFinalAnswer(
        this.itemList[14].anwser,
        this.itemList[14].options
      );
      RecordQ4.Question_16 = this.getFinalAnswer(
        this.itemList[15].anwser,
        this.itemList[15].options
      );
      RecordQ4.Question_16_Other = this.itemList[15].other;
      RecordQ4.Question_17 = this.itemList[16].anwser;
      RecordQ4.Question_18 = this.getFinalAnswer(
        this.itemList[17].anwser,
        this.itemList[17].options
      );
      RecordQ4.Question_19 = this.getFinalAnswer(
        this.itemList[18].anwser,
        this.itemList[18].options
      );

      let obj = {};
      obj.RecordQ4 = RecordQ4;
      // obj.RecordBase = this.allData.RecordBase;
      obj.RecordBase = store.editItem.RecordBase;

      try {
        await window.axios.post("LocalRecord/SetAnswer", obj);
        this.$root.$emit("get-newestsurvey-data");
        this.$bvToast.toast(`儲存成功`, {
          title: "問卷調查",
          autoHideDelay: 5000,
          variant: "success",
        });
      } catch (e) {
        alert(e);
      }
    },
    getOriginalAnswer(final, options) {
      let originalAnswer = [];
      originalAnswer =
        (final &&
          final
            .split(",")
            .map((s, i) => (s == "Y" ? options[i] : ""))
            .filter((s) => s)) ||
        [];

      return originalAnswer;
    },
    fillForm() {
      let items = { ...this.allData.RecordQ4 };
      this.itemList[0].anwser = this.getOriginalAnswer(
        items.Question_01,
        this.itemList[0].options
      );
      this.itemList[0].other = items.Question_01_Other
        ? items.Question_01_Other
        : "";
      this.itemList[1].anwser = this.getOriginalAnswer(
        items.Question_02,
        this.itemList[1].options
      );
      this.itemList[1].other = items.Question_02_Other
        ? items.Question_02_Other
        : "";
      this.itemList[2].anwser = this.getOriginalAnswer(
        items.Question_03,
        this.itemList[2].options
      );
      this.itemList[2].other = items.Question_03_Other
        ? items.Question_03_Other
        : "";
      this.itemList[3].anwser = this.getOriginalAnswer(
        items.Question_04,
        this.itemList[3].options
      );
      this.itemList[3].other = items.Question_04_Other
        ? items.Question_04_Other
        : "";
      this.itemList[4].anwser = this.getOriginalAnswer(
        items.Question_05,
        this.itemList[4].options
      )[0];
      this.itemList[5].anwser = this.getOriginalAnswer(
        items.Question_06,
        this.itemList[5].options
      )[0];
      this.itemList[6].anwser = this.getOriginalAnswer(
        items.Question_07,
        this.itemList[6].options
      );
      this.itemList[6].other = items.Question_07_Other
        ? items.Question_07_Other
        : "";
      this.itemList[7].anwser = this.getOriginalAnswer(
        items.Question_08,
        this.itemList[7].options
      )[0];
      this.itemList[8].anwser = this.getOriginalAnswer(
        items.Question_09,
        this.itemList[8].options
      )[0];
      this.itemList[9].anwser = this.getOriginalAnswer(
        items.Question_10,
        this.itemList[9].options
      );
      this.itemList[9].other = items.Question_10_Other
        ? items.Question_10_Other
        : "";
      this.itemList[10].anwser = this.getOriginalAnswer(
        items.Question_11,
        this.itemList[10].options
      );
      this.itemList[10].other = items.Question_11_Other
        ? items.Question_11_Other
        : "";
      this.itemList[11].anwser = this.getOriginalAnswer(
        items.Question_12,
        this.itemList[11].options
      );
      this.itemList[11].other = items.Question_12_Other
        ? items.Question_12_Other
        : "";
      this.itemList[12].anwser = this.getOriginalAnswer(
        items.Question_13,
        this.itemList[12].options
      );
      this.itemList[12].other = items.Question_13_Other
        ? items.Question_13_Other
        : "";
      this.itemList[13].anwser = this.getOriginalAnswer(
        items.Question_14,
        this.itemList[13].options
      );
      this.itemList[13].other = items.Question_14_Other
        ? items.Question_14_Other
        : "";
      this.itemList[14].anwser = this.getOriginalAnswer(
        items.Question_15,
        this.itemList[14].options
      )[0];
      this.itemList[15].anwser = this.getOriginalAnswer(
        items.Question_16,
        this.itemList[15].options
      );
      this.itemList[15].other = items.Question_16_Other
        ? items.Question_16_Other
        : "";
      this.itemList[16].anwser = items.Question_17;
      this.itemList[17].anwser = this.getOriginalAnswer(
        items.Question_18,
        this.itemList[17].options
      )[0];
      this.itemList[18].anwser = this.getOriginalAnswer(
        items.Question_19,
        this.itemList[18].options
      )[0];
    },
  },
  computed: {
    watchAnswer() {
      // let q1String = this.itemList[0].anwser ?`${this.itemList[0].anwser.join(
      //   ",")` :'';
      let q1 = this.itemList[0].anwser
        ? `${this.itemList[0].anwser.join(",")}`
        : "";
      let q2 = this.itemList[1].anwser
        ? `${this.itemList[1].anwser.join(",")}`
        : "";
      let q3 = this.itemList[2].anwser
        ? `${this.itemList[2].anwser.join(",")}`
        : "";

      let obj = `${q1}${q2}${q3}`;
      return obj;
    },
  },
  mounted() {
    this.fillForm();
    this.closeBtn = store.editPerson ? store.editPerson.closeBtn : false;
    if (this.closeBtn) {
      document.querySelectorAll(".input-container input").forEach((e) => {
        e.disabled = true;
      });
    }
  },
  watch: {
    watchAnswer() {
      if (this.itemList[0].anwser.length > 0) {
        this.isQ1Exist = true;
        this.isQ2Exist = false;
        this.isQ3Exist = false;
      } else if (this.itemList[1].anwser.length > 0) {
        this.isQ1Exist = false;
        this.isQ2Exist = true;
        this.isQ3Exist = false;
      } else if (this.itemList[2].anwser.length > 0) {
        this.isQ1Exist = false;
        this.isQ2Exist = false;
        this.isQ3Exist = true;
      } else {
        this.isQ1Exist = false;
        this.isQ2Exist = false;
        this.isQ3Exist = false;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dtc-main-section {
  max-width: 890px;
  margin: 30px auto;
  display: grid;
}
.dtc-2-column {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border: 1px solid black;
  border-top: none;
  > div {
    padding: 10px;
  }
  > div:first-child {
    border-right: 1px solid black;
    font-size: 16px;
  }
}
.header-dark {
  border: 1px solid black;
  line-height: 7px;
}
.number-div {
  display: grid;
  grid-template-columns: 10px 1fr;
  gap: 1rem;
}
.bold-text-before {
  position: relative;
  margin-top: 30px;
  &::before {
    position: absolute;
    top: -30px;
    font-weight: 900px !important;
    content: "需求評估:";
  }
}
main.dtc-2-column.active {
  border: 0px;
}
</style>
